/* eslint-disable no-nested-ternary */
import React, { MouseEventHandler } from 'react';
import Image from 'next/image';
import { Price } from '../../interfaces/plan';
import styles from '../../styles/ChangeYourPlan.module.css';
import Button from '../Button';
import { getCurrencySymbol } from '../../const/plan';

interface PlanCardProps {
  price: Price;
  period: string;
  chosen: boolean;
  montlyAlternative: Price;
  onChoose: Function;
  tag?: string;
  isLoading?: boolean;
}

const PlanCard = ({
  price,
  period,
  chosen,
  montlyAlternative,
  onChoose,
  tag,
  isLoading,
}: PlanCardProps) => {
  const montlyAmount =
    price.period === 'year' ? parseFloat((price.amount / 12).toFixed(2)) : price.amount;

  const renderHTML = (rawHTML: string) =>
    React.createElement('span', { dangerouslySetInnerHTML: { __html: rawHTML } });

  const isPremiumRewards = price.plan.toLowerCase().includes('premium rewards');

  return (
    <div className={`${styles.cardWrapper} ${tag ? styles.tagSpacing : ''}`}>
      {tag && <div className={styles.tag}>{tag}</div>}
      <h3
        className={`uppercase text-xs md:text-sm font-sfProTextBold text-gray-1000 pb-4 ${
          tag ? styles.tagExtraPadding : ''
        }`}
      >
        {price.plan}
      </h3>
      <p className="font-sfProTextRegular text-sm md:text-base text-gray-600 pb-4">
        {price.description}
      </p>
      <div className="flex gap-3 font-tommyBold text-4xl md:text-5xl pb-4">
        {montlyAlternative.amount > montlyAmount && !isPremiumRewards && (
          <p className="line-through text-gray-500">
            {getCurrencySymbol(price.currency)}
            {montlyAlternative.amount}
          </p>
        )}
        {montlyAmount === 0 && isPremiumRewards && period === 'month' ? (
          <p>Annual Only</p>
        ) : montlyAmount === 0 ? (
          <p>Free</p>
        ) : (
          <p>
            {getCurrencySymbol(price.currency)}
            {montlyAmount}
          </p>
        )}
      </div>
      <div className={styles.currencyRate}>
        <p>{price.currency}</p>
        <p>/</p>
        <p>month</p>
      </div>
      <Button
        handleClick={onChoose as MouseEventHandler<HTMLButtonElement>}
        canActivate={!chosen && !isLoading && montlyAmount !== 0}
        leftIconName={isLoading ? 'loading' : ''}
        btnContext={
          isPremiumRewards && period === 'month'
            ? 'unavailable'
            : chosen
            ? 'current plan'
            : (montlyAmount && `Join ${price.plan}`) || 'get started'
        }
        customClasses=" mb-8 w-full"
      />
      <div className="flex flex-col gap-2">
        {price.benefits?.map((item) => (
          <div className={styles.planItem}>
            <Image src="/icons/green-check.svg" width={20} height={20} />
            <div className="font-sfProTextRegular text-sm md:text-base text-gray-700">
              {renderHTML(item)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PlanCard.defaultProps = {
  tag: '',
  isLoading: false,
};

export default PlanCard;
